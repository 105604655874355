import React, { useState } from "react";
import "./App.css";

function VideoTranscriptionForm() {
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [transcriptionType, setTranscriptionType] = useState("audio");
  const [file, setFile] = useState(null);
  const [output, setOutput] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [isTimestamp, setIsTimestamp] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDeleteFile = () => {
    setFile(null);
    document.getElementById("file-input").value = ""; // Reset the file input field
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please upload a video file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);
    formData.append("transcriptionType", transcriptionType);
    formData.append("isTimestamp", isTimestamp);

    try {
      const response = await fetch(
        `https://api.video-transcription-aipi.com/upload-document`,
        {
          method: "POST",
          body: formData,
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const formattedOutput = data.result
        .replace(/\\n/g, "\n")
        .replace(/\\"/g, '"');
      setOutput(formattedOutput);
    } catch (error) {
      setOutput(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setOutput("Processing...");
    if (!url && !file) {
      alert("Please provide a video URL or upload a file.");
    } else if (file) {
      return handleUpload(e);
    } else {
      try {
        const response = await fetch(
          "https://api.video-transcription-aipi.com/transcribe",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url,
              description,
              transcriptionType,
              isTimestamp,
            }),
          },
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const formattedOutput = data.result
          .replace(/\\n/g, "\n")
          .replace(/\\"/g, '"');
        setOutput(formattedOutput);
      } catch (error) {
        setOutput(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
        setSummary(null);
      }
    }
  };

  const handleSummarize = async () => {
    setSummary("Processing...");
    setIsSummarizing(true);
    try {
      const response = await fetch(
        "https://api.video-transcription-aipi.com/summary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: output,
          }),
        },
      );

      const data = await response.json();
      setSummary(data.summary);
    } catch (error) {
      console.error("Error:", error);
      setSummary("An error occurred");
    }

    setIsSummarizing(false);
  };

  return (
    <div className="form-container">
      <h1>Video Transcription (AI)PI</h1>
      <form onSubmit={handleSubmit} className="transcription-form">
        <div className="form-row">
          {!file ? (
            <div className="form-group">
              <label htmlFor="url">Video URL</label>
              <input
                type="text"
                id="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Google Drive, X (Twitter), YouTube, or direct MP4 link"
              />
            </div>
          ) : null}

          {!url ? (
            <div className="form-group">
              <label htmlFor="file">Upload File</label>
              <input
                type="file"
                id="file-input"
                accept="video/*, .mp4, .webm, .avi, .mov, .mkv, .flv, .wmv, .ogg"
                onChange={handleFileChange}
              />
              {file ? (
                <button
                  type="button"
                  onClick={handleDeleteFile}
                  className="delete-button"
                >
                  Delete File
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="description">Description (Optional)</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="This is a lecture presented by LlamaIndex describing RAG (Retrieval Augmented Generation)"
          />
        </div>
        <div className="form-group">
          <br />
          The 'Description' should typically be a sentence that encapsulates the
          events of the video. In fringe circumstances, where it would be
          expected that the LLM does not know about an object or concept, more
          detail should be given.
          <br />
          You can also use the description box to pass in instructions. <br />
          <div className="radio-group">
            <label className="custom-radio">
              <input
                type="radio"
                value="audio"
                checked={transcriptionType === "audio"}
                onChange={(e) => setTranscriptionType(e.target.value)}
              />
              <span className="radiomark"></span>
              Audio Only
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                value="video"
                checked={transcriptionType === "video"}
                onChange={(e) => setTranscriptionType(e.target.value)}
              />
              <span className="radiomark"></span>
              Video Only
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                value="both"
                checked={transcriptionType === "both"}
                onChange={(e) => setTranscriptionType(e.target.value)}
              />
              <span className="radiomark"></span>
              Both Audio and Video
            </label>
          </div>{" "}
          <br />
          Audio transcription works well when you have a very clear voiceover or
          when you want to transcribe a podcast.
          <br />
          Video works well when you have a video with captions on the screen or
          when you just want a general rundown of the video itself. <br />
          'Both Audio and Video' uses more granular fragmentation, and takes a
          lot longer to perform, but will provide much more detail. This mode is
          optimal for use in pipelines with LLMs, or for generating high-quality
          summaries. <br />
          <br />
        </div>
        {/* Now let's add a checkbox to add separation by timestamps (for audio) and slides (for video)*/}
        {/* Add a checkbox to enable timestamp separation for audio transcriptions */}
        <div className="checkbox-container">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={isTimestamp}
              onChange={(e) => setIsTimestamp(e.target.checked)}
            />
            <span className="checkmark"></span>
            Timestamp Separation
          </label>
        </div>
        <button
          disabled={isLoading}
          onClick={handleSubmit}
          className="submit-button"
        >
          {isLoading ? "Processing..." : "Transcribe"}
        </button>
      </form>
      {output && (
        <div className="output-section">
          <h2>Transcription Output:</h2>
          <textarea value={output} readOnly className="output-textarea" />
          {!isLoading && (
            <button disabled={isSummarizing} onClick={handleSummarize}>
              {isSummarizing ? "Summarizing..." : "Summarize"}
            </button>
          )}
          {summary && !isLoading && (
            <div>
              <h2>Summary:</h2>
              <textarea value={summary} readOnly className="output-textarea" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default VideoTranscriptionForm;
