import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-qZRdh6GpkFp1qG97uCaEBM-fIRUgPwo",
  authDomain: "ixl-replacement.firebaseapp.com",
  projectId: "ixl-replacement",
  storageBucket: "ixl-replacement.appspot.com",
  messagingSenderId: "772043360669",
  appId: "1:772043360669:web:8985711e53906843940774",
  measurementId: "G-95JYBXG77H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
