import React, { useState, useEffect } from "react";
import { auth, provider } from "./firebase";
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import VideoTranscriptionForm from "./VideoTranscriptionForm";
import "./App.css"; // Import the CSS file

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Monitor authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log("User signed in:", user);
      })
      .catch((error) => {
        console.error("Error during Google Sign-In:", error.message);
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
      })
      .catch((error) => {
        console.error("Error during sign out:", error.message);
      });
  };

  return (
    <div className="App">
      {user ? (
        <div className="sign-out-container">
          <button className="sign-out-button" onClick={handleSignOut}>
            Sign Out
          </button>
        </div>
      ) : (
        <div></div>
      )}
      <div className="container">
        {!user ? (
          <div className="auth-container">
            <h1>Video Transcription (AI)PI Sign In</h1>
            <button className="sign-in-button" onClick={handleSignIn}>
              Sign In with Google
            </button>
          </div>
        ) : (
          <div className="auth-container">
            <VideoTranscriptionForm />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
